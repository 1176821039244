<template>
<v-form v-model="valid">
    <v-alert v-if="semCiclo" type="error" prominent>
        <div class="text-h6">
            <strong>Nenhuma avaliação.</strong>
        </div>
        <span>
            No momento não existe nenhum ciclo vigente.
        </span>
    </v-alert>
    <div v-else class="pa-3">
        <h3 class="text-center grey--text text--darken-1 font-weight-light pb-5">
            Cadastro de avaliador/avaliado para Avaliação de Desempenho Individual<br> 
            dos servidores externos <span v-if="ciclosVigentes[0]">{{ ciclosVigentes[0].nm_ciclo }}</span>
        </h3>
        <v-form class="mt-5" ref="form_avaliador_externo">
            <v-col cols="12" class="mt-4">
                <v-card class="pb-3">
                    <h3 class="pa-5 primary--text">Avaliador</h3>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="4" class="mx-0 px-3">
                                <v-text-field id="cpf_avaliador" ref="cpf" dense hide-details="auto" label="CPF do avaliador" v-model="avaliador.nr_cpf" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" v-on:change="getAvaliadorByCPF(`${avaliador.nr_cpf}`)" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="mx-0 px-3">
                                <v-text-field id="nome_avaliador"  ref="nome_avaliador" dense hide-details="auto" label="Nome do avaliador" outlined clearable clear-icon="mdi-close-circle-outline" v-model="avaliador.nm_servidor" :rules="validaCampo" :disabled="nomeDisabled"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="mx-0 px-3">
                                <v-text-field id="orgao_lotacao_avaliador" v-model="avaliador.nm_orgao_lotacao" ref="orgao_lotacao_avaliador" dense hide-details="auto" label="Órgão de exercício" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" :disabled="orgaoDisabled">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="4" class="mx-0 px-3">
                                <v-text-field id="cargo_avaliador" v-model="avaliador.nm_cargo" ref="cargo_avaliador" dense hide-details="auto" label="Cargo do avaliador" outlined clearable clear-icon="mdi-close-circle-outline" :disabled="cargoDisabled">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="mx-0 px-3">
                                <v-text-field id="funcao_avaliador" v-model="avaliador.nm_funcao" ref="funcao_avaliador" dense hide-details="auto" label="Função do avaliador " outlined clearable clear-icon="mdi-close-circle-outline" :disabled="funcaoDisabled">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="mx-0 px-3">
                                <v-text-field id="telefone_avaliador" v-model="avaliador.nr_telefone_pessoal" ref="telefone_avaliador" dense hide-details="auto" label="Telefone do avaliador" v-mask="'(##) ####-#####'" outlined clearable clear-icon="mdi-close-circle-outline" :disabled="telefoneDisabled">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-text-field id="unidade_avaliador" v-model="avaliador.nm_unidade_exercicio" ref="unidade_avaliador" dense hide-details="auto" label="Unidade de exercício" outlined clearable clear-icon="mdi-close-circle-outline" :disabled="unidadeDisabled">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-text-field id="email_avaliador" v-model="avaliador.ds_email_institucional" ref="email_avaliador" dense hide-details="auto" label="E-mail institucional do avaliador" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaEmail" :disabled="emailDisabled">
                                </v-text-field>
                            </v-col>
                        </v-row>
                </v-card>
            </v-col>
        </v-form>

        <v-col cols="12">
            <v-card class="pb-3">
                <v-container>
                    <template>
                    <v-data-table :headers="headers" :items="avaliados" sort-by="nome_avaliado" class="elevation-1">
                        <template v-slot:top>
                        <v-toolbar flat>
                            <h3 class="primary--text">Avaliados</h3>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on">
                                Adicionar avaliado
                                </v-btn>
                            </template>
                            <v-card>
                                <h2 class="primary--text text-center py-6">{{ formTitle }}</h2>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <v-container>
                                        <v-row dense class="ma-0">
                                            <v-col cols="12" md="3" class="mx-0 px-3">
                                                <v-text-field v-model="editedItem.cpf_avaliado" v-mask="'###.###.###-##'" label="CPF" :rules="validaCampo" v-on:change="getAvaliadoByCPF(`${editedItem.cpf_avaliado}`)"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6" class="mx-0 px-3">
                                                <v-text-field v-model="editedItem.nome_avaliado" label="Nome" disabled></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="3" class="mx-0 px-3">
                                                <v-text-field v-model="editedItem.telefone_avaliado" v-mask="'(##) ####-#####'" label="Telefone" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row dense class="ma-0">
                                                <v-col cols="12" md="6" class="mx-0 px-3">
                                                <v-text-field v-model="editedItem.email_pessoal_avaliado" label="E-mail pessoal" disabled></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6" class="mx-0 px-3">
                                                <v-text-field v-model="editedItem.email_institucional_avaliado" label="E-mail institucional" disabled></v-text-field>
                                            </v-col>
                                        </v-row>    
                                        <v-row dense class="ma-0">
                                            <v-col cols="12" md="12" class="mx-0 px-3">
                                                <v-text-field v-model="editedItem.nm_orgao" label="Órgão" disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    Fechar
                                </v-btn>
                                <v-btn color="blue darken-1" :disabled="isDisabled" text @click="save">
                                    Salvar
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-center font-weight-light">Tem certeza que deseja excluir esse avaliado?</v-card-title>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Não</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sim</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                        </template>
                        <template v-slot:no-data>
                            <h4>Nenhum avaliado adicionado</h4>
                        </template>
                    </v-data-table>
                    </template>
                </v-container>
            </v-card>
        </v-col>
        <br />
        <v-row>
            <v-col class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn id="btnFiltrar" color="primary" @click="enviarDados()">
                    Enviar dados avaliador/avaliado
                </v-btn>
            </v-col>
        </v-row>
    </div>

    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{msgDialog}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    
    <v-dialog v-model="dialogError" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">{{msgDialog}}</v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogError = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogErrorPossuiPlano" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            Servidor já consta em plano de trabalho<br>
                              Favor entrar em contato com a<br> 
                             <h3>diade.codep.cgdep@ecnomia.gov.br</h3>
                             para solicitar o ajuste, se for o caso.
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogErrorPossuiPlano = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPossuiCadastro" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            Já existe um avaliador com esse CPF.<br>
                            Sendo possível apenas inserir mais avaliados.
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogPossuiCadastro = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
</v-form>


</template>

<script>
import { getServidorExternoByCPF, getServidorByCPF, enviarCedidoRequisitado, enviarAvaliados, getEquipeTrabalhoExternoByServidor } from '../api/servidor';
import { getCiclo, getCiclosVigentes } from '../api/ciclo';
import { getServidorByCpfForGestor } from '../api/servidor';
import moment from 'moment';

export default {
    name: 'AvaliadoresAvaliadosExternoCadastrar',
    data() {
        return {
            avaliador: {
                nr_cpf:'',
                nm_servidor:'',
                nm_orgao_lotacao:'',
                nm_unidade_exercicio:'',
                nm_cargo:'',
                nm_funcao:'',
                nr_telefone_pessoal:'',
                ds_email_institucional:'',
                avaliados:[],
                dt_inicio_avaliacao:'',
                dt_fim_avaliacao:'',
            },
            
            valid: false,
            validaCampo: [
                v => !!v || 'Campo obrigatório',
            ],
            validaEmail: [
                v => !!v || 'E-mail é obrigatório',
                v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail inválido',
            ],
            dialog: false,
            dialogSuccess: false,
            dialogError : false,
            dialogDelete: false,
            msgDialog: '',
            headers: [
                { text: 'CPF do avaliado',align: 'start',sortable: false,value: 'cpf_avaliado'},
                { text: 'Nome do avaliado', value: 'nome_avaliado' },
                { text: 'E-mail institucional do avaliado', value: 'email_institucional_avaliado' },
                { text: 'E-mail pessoal do avaliado', value: 'email_pessoal_avaliado' },
                { text: 'Telefone do avaliado', value: 'telefone_avaliado' },
                { text: 'Órgão', value: 'nm_orgao' },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            avaliados: [],
            editedIndex: -1,
            editedItem: {
                id_servidor:'',
                cpf_avaliado: '',
                nome_avaliado: '',
                email_institucional_avaliado: '',
                email_pessoal_avaliado: '',
                telefone_avaliado: '',
                nm_orgao: ''
            },
            defaultItem:{
                id_servidor:'',
                cpf_avaliado: '',
                nome_avaliado: '',
                email_institucional_avaliado: '',
                email_pessoal_avaliado: '',
                telefone_avaliado: '',
                nm_orgao: ''
            },
            qtdAvaliados:0,
            subselecao:[],
            ciclosVigentes: [],
            dt_encerramento_avaliacao: '',
            dt_inicio_avaliacao:'',
            showForm:true,
            nomeDisabled:false,
            orgaoDisabled:false,
            cargoDisabled:false,
            funcaoDisabled:false,
            unidadeDisabled:false,
            telefoneDisabled:false,
            emailDisabled:false,
            dialogPossuiCadastro:false,
            dialogErrorPossuiPlano:false,
            semCiclo: false
        }
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Adicionar avaliado' : 'Editar avaliado';
        },

        isDisabled() {
            var cpf = this.validaCPF(this.editedItem.cpf_avaliado);
            if (cpf) {
                return false;
            } else{
                return true;
            }
        },

        isDisabledEnviar() {
            if (this.avaliador.nr_cpf != '' && this.avaliador.nr_cpf != null &&
                this.avaliador.nm_servidor != '' && this.avaliador.nm_servidor != null &&
                this.avaliador.nm_orgao_lotacao != '' && this.avaliador.nm_orgao_lotacao != null &&
                this.avaliador.nm_unidade_exercicio != '' && this.avaliador.nm_unidade_exercicio != null &&
                this.avaliador.nm_cargo != '' && this.avaliador.nm_cargo != null &&
                this.avaliador.nm_funcao != '' && this.avaliador.nm_funcao != null &&
                this.avaliador.nr_telefone_pessoal != '' && this.avaliador.nr_telefone_pessoal != null &&
                this.avaliador.ds_email_institucional != '' && this.avaliador.ds_email_institucional != null) {
                return false;
            } else{
                return true;
            } 
        },
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
        dialogDelete (val) {
            val || this.closeDelete();
        },
    },
    created() {
        getCiclosVigentes().then(response => {
            this.ciclosVigentes = response.data.data;
            let ultimoCicloVigente = this.ciclosVigentes[Object.keys(this.ciclosVigentes).pop()];

            getCiclo(ultimoCicloVigente.id_ciclo).then(response => {
                this.dt_inicio_avaliacao = response.data.data.fases.find(({ id_fase }) => id_fase === 3).dt_inicio;;
                this.dt_encerramento_avaliacao = response.data.data.fases.find(({ id_fase }) => id_fase === 3).dt_encerramento;
                var dt_agora = moment().format('YYYY/MM/DD');
                var dt_encerramento =moment(this.dt_encerramento_avaliacao, 'DD/MM/YYYY').format('YYYY/MM/DD');

                if (dt_agora <= dt_encerramento) {
                    this.showForm= true;
                } else {
                    this.showForm= false;
                }

                if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0) {
                    this.showForm = true;
                }
            });
        }).catch(erro => {
            this.semCiclo = true;
            console.log('erro na busca de ciclos vigentes');
        });;
    },

    methods: {
        editItem (item) {
            this.editedIndex = this.avaliados.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem (item) {
            this.editedIndex = this.avaliados.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm () {
            this.avaliados.splice(this.editedIndex, 1);
            this.closeDelete();
            this.dialogSuccess = true;
        },

        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({},this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({},this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save () {
            getEquipeTrabalhoExternoByServidor(this.editedItem.id_servidor).then(response => {  
                if (response.data.data) {
                    this.editedItem = Object.assign({},this.defaultItem);
                    this.msgDialog = "Servidor a ser cadastrado como avaliado já foi cadastrado anteriormente no ciclo vigente";
                    return this.dialogError = true;
                } else {

                    if (response.data.message == 'bloqueioExterno') {
                        this.editedItem = Object.assign({},this.defaultItem);
                         this.msgDialog = "Servidor com avaliação externa bloqueada";
                        return this.dialogError = true;
                    }
                    
                    if (response.data.message == 'naoAvalia') {
                        this.editedItem = Object.assign({},this.defaultItem);
                        this.msgDialog = "Servidor a ser cadastrado como avaliado não é avaliado no ciclo vigente";
                        return this.dialogError = true;
                    }

                    if (response.data.message == 'possuiPlano') {
                        this.editedItem = Object.assign({},this.defaultItem);
                        return this.dialogErrorPossuiPlano = true;
                    }

                    if (this.editedIndex > -1) {
                        Object.assign(this.avaliados[this.editedIndex], this.editedItem);
                    } else {
                        this.qtdAvaliados +=1;
                        this.avaliados.push(this.editedItem);
                    }

                    this.close();
                    this.msgDialog = 'Feito com sucesso!';
                    return this.dialogSuccess = true;
                }
            });
        },

        getAvaliadorByCPF (cpf) {
            var cpfValido = this.validaCPF(cpf);

            if (!cpfValido) {
                this.avaliador.nr_cpf = '';
                this.msgDialog = "CPF informado está inválido!";
                return this.dialogError = true;
            }

            if (this.qtdAvaliados > 0) {
                var cpfExistente = false;
                this.avaliados.map(function(v) {
                   if (v.cpf_avaliado == cpf) {
                       cpfExistente= true;
                   } 
                });

                if (cpfExistente) {
                    this.avaliador.nr_cpf = '';
                    this.msgDialog = "Já existe esse CPF na sua lista de avaliados!";
                    return this.dialogError = true;
                }
            }

            var servidor = null;
            getServidorExternoByCPF(cpf).then(response => {
                servidor = response.data;
                if (!servidor.data) {
                    this.avaliador.nr_cpf = '';
                    return this.dialogErrorPossuiPlano = true;
                } else {
                    this.avaliador.nm_servidor = servidor.data.nm_servidor;
                    this.avaliador.nm_orgao_lotacao = servidor.data.orgao_servidor;
                    this.avaliador.nm_cargo = servidor.data.cargo_servidor;
                    this.avaliador.nm_funcao= servidor.data.funcao_servidor;
                    this.avaliador.nm_unidade_exercicio = servidor.data.unidade_servidor;
                    this.avaliador.nr_telefone_pessoal =servidor.data.nr_telefone_pessoal;
                    this.avaliador.ds_email_institucional = servidor.data.ds_email_institucional;

                    if (servidor.data.length == 0) {
                        this.nomeDisabled = false;
                        this.orgaoDisabled = false;
                        this.cargoDisabled = false;
                        this.funcaoDisabled = false;
                        this.unidadeDisabled = false;
                        this.telefoneDisabled =false;
                        this.emailDisabled =false;
                    } else {
                        if (servidor.data.ds_email_institucional !=='') {
                            this.dialogPossuiCadastro = true;
                        }
                        this.nomeDisabled = servidor.data.nm_servidor ==''?false:true;
                        this.orgaoDisabled = servidor.data.orgao_servidor ==''?false:true;
                        this.cargoDisabled = servidor.data.cargo_servidor ==''?false:true;
                        this.funcaoDisabled = servidor.data.funcao_servidor ==''?false:true;
                        this.unidadeDisabled = servidor.data.unidade_servidor ==''?false:true;
                        this.telefoneDisabled = servidor.data.nr_telefone_pessoal==''?false:true;
                        this.emailDisabled = servidor.data.ds_email_institucional==''?false:true;
                    }
                    
                }                    
                }).catch(erro => {
                    this.avaliador.nm_servidor = '';
                    this.avaliador.nm_orgao_lotacao = '';
                    this.avaliador.nm_unidade_exercicio = '';
                    this.avaliador.nm_cargo = '';
                    this.avaliador.nm_funcao = '';
                    this.avaliador.nr_telefone_pessoal='';
                    this.avaliador.ds_email_institucional='';
                });
        },

        getAvaliadoByCPF (cpf) {
            var cpfValido = this.validaCPF(cpf);
            
            if (this.qtdAvaliados > 0) {
                var cpfExistente = false;
                this.avaliados.map(function(v) {
                   if (v.cpf_avaliado == cpf) {
                       cpfExistente= true;
                   } 
                });

                if (cpfExistente) {
                    this.editedItem.cpf_avaliado = '';
                    this.msgDialog = "Já existe esse CPF na sua lista de avaliados!";
                    return this.dialogError = true;
                }
            }

            if (this.avaliador.nr_cpf == cpf) {
                this.editedItem.cpf_avaliado = '';
                this.msgDialog = "Não é permitido que servidor seja cadastrado como seu próprio avaliador";
                return this.dialogError = true;
            }

            if (!cpfValido) {
                this.editedItem.cpf_avaliado = '';
                this.msgDialog = "CPF informado está inválido!";
                return this.dialogError = true;
            }

            var avaliado = null;

            if (
                this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
            ) {
                getServidorByCpfForGestor(cpf).then(response => { 

                    if (response.data.message == 'outroOrgao') {
                        this.msgDialog = "Servidor de outro órgão!";
                        this.editedItem = Object.assign({},this.defaultItem);
                        return this.dialogError = true;
                    }else {
                        avaliado = response.data.data;
                        this.editedItem.id_servidor = avaliado.id_servidor;
                        this.editedItem.cpf_avaliado = avaliado.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                        this.editedItem.nome_avaliado = avaliado.nm_servidor;
                        this.editedItem.email_institucional_avaliado = avaliado.ds_email_institucional;
                        this.editedItem.email_pessoal_avaliado = avaliado.ds_email_pessoal;
                        this.editedItem.telefone_avaliado = avaliado.nr_telefone;
                        this.editedItem.nm_orgao = avaliado.nm_orgao;
                    }
                }).catch(erro => {
                    this.msgDialog = "CPF do avalido não encontrado! Verifique se o número está correto.";
                    this.dialogError = true;
                    this.editedItem = Object.assign({},this.defaultItem);
                });
            } else {
                getServidorByCPF(cpf).then(response => {
                    avaliado = response.data.data;
                    this.editedItem.id_servidor = avaliado.id_servidor;
                    this.editedItem.cpf_avaliado = avaliado.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                    this.editedItem.nome_avaliado = avaliado.nm_servidor;
                    this.editedItem.email_institucional_avaliado = avaliado.ds_email_institucional;
                    this.editedItem.email_pessoal_avaliado = avaliado.ds_email_pessoal;
                    this.editedItem.telefone_avaliado = avaliado.nr_telefone;
                    this.editedItem.nm_orgao = avaliado.nm_orgao;
                }).catch(erro => {
                    this.msgDialog = "CPF do avalido não encontrado! Verifique se o número está correto.";
                    this.dialogError = true;
                    this.editedItem = Object.assign({},this.defaultItem);
                });
            }
        },

        validaCPF (strCPF) {
            strCPF = strCPF.replaceAll('.','').replaceAll('-','');
            var Soma;
            var Resto;
            var i = null;
            Soma = 0;
            if (strCPF == "00000000000") return false;
            if (strCPF == "11111111111") return false;
            if (strCPF == "22222222222") return false;
            if (strCPF == "33333333333") return false;
            if (strCPF == "44444444444") return false;
            if (strCPF == "55555555555") return false;
            if (strCPF == "66666666666") return false;
            if (strCPF == "77777777777") return false;
            if (strCPF == "88888888888") return false;
            if (strCPF == "99999999999") return false;

            for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
            return true;
        },

        enviarDados () {
            if ( this.$refs['form_avaliador_externo'].validate() ) {
                var id_externo = null;
                var nome_externo = null;
                var cpf_externo = null;
                this.avaliador.nr_cpf = this.avaliador.nr_cpf.replaceAll('.','').replaceAll('-','');

                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.avaliador.ds_email_institucional) == false) {
                    this.msgDialog = 'E-mail do avaliador está inválido';
                    return this.dialogError = true;
                }

                if (this.qtdAvaliados == 0) {
                    this.msgDialog = "Nenhum avaliado adicionado!";
                    return this.dialogError = true;
                }

                this.avaliador.avaliados = this.avaliados;
                this.avaliador.dt_inicio_avaliacao = this.dt_inicio_avaliacao;
                this.avaliador.dt_fim_avaliacao = this.dt_encerramento_avaliacao;

                enviarCedidoRequisitado(JSON.stringify(this.avaliador)).then(response => {
                    id_externo = response.data.data.id_servidor_externo;
                    nome_externo = response.data.data.nm_servidor;
                    cpf_externo = response.data.data.nr_cpf;
                    if (id_externo >0) {
                        this.subselecao = this.avaliados.map(function(v) {
                            return {
                                id_servidor: v.id_servidor,
                                id_servidor_externo: id_externo,
                                email_servidor: v.email_institucional_avaliado,
                                email_pessoal: v.email_pessoal_avaliado,
                                nome_externo: nome_externo,
                                cpf_externo: cpf_externo,
                            };
                        });
                        enviarAvaliados(JSON.stringify(this.subselecao)).then(response => {
                            this.avaliador.nr_cpf='',
                            this.avaliador.nm_servidor='',
                            this.avaliador.nm_orgao_lotacao='',
                            this.avaliador.nm_unidade_exercicio='',
                            this.avaliador.nm_cargo='',
                            this.avaliador.nm_funcao='',
                            this.avaliador.nr_telefone_pessoal='',
                            this.avaliador.ds_email_institucional='',
                            this.avaliador.avaliados=[],
                            this.avaliados = [];
                            this.editedItem = Object.assign({},this.defaultItem);
                            this.msgDialog = response.data.message;
                            this.dialogSuccess = true;
                        }); 
                    }
                });
            }
        },
    },
}
</script>
